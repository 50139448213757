{
  "name": "browser-dtector",
  "version": "4.1.0",
  "description": "A Javascript library to detect browser, version and platform",
  "author": "sibiraj-s",
  "license": "MIT",
  "type": "module",
  "repository": "https://github.com/sibiraj-s/browser-dtector.git",
  "homepage": "https://github.com/sibiraj-s/browser-dtector#readme",
  "bugs": "https://github.com/sibiraj-s/browser-dtector/issues",
  "funding": [
    {
      "type": "github",
      "url": "https://github.com/sponsors/sibiraj-s"
    }
  ],
  "engines": {
    "node": ">=16"
  },
  "sideEffects": false,
  "files": [
    "dist"
  ],
  "main": "./dist/browser-dtector.cjs",
  "module": "./dist/browser-dtector.js",
  "types": "./dist/browser-dtector.d.ts",
  "exports": {
    ".": {
      "require": {
        "types": "./dist/browser-dtector.d.cts",
        "default": "./dist/browser-dtector.cjs"
      },
      "import": "./dist/browser-dtector.js",
      "types": "./dist/browser-dtector.d.ts"
    },
    "./package.json": "./package.json"
  },
  "scripts": {
    "build": "tsup",
    "build:docs": "vite build --base /browser-dtector/",
    "serve": "vite",
    "test": "vitest",
    "lint": "eslint . --ext .js,.ts --ignore-path .gitignore",
    "prepare": "is-ci || husky install"
  },
  "keywords": [
    "browser-detector",
    "detect-browser",
    "useragent-parser",
    "ua-parser"
  ],
  "devDependencies": {
    "@commitlint/cli": "^18.2.0",
    "@commitlint/config-conventional": "^18.1.0",
    "@types/react": "^18.2.34",
    "@types/react-dom": "^18.2.14",
    "eslint": "^8.52.0",
    "eslint-config-pegasus": "^5.0.0",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "happy-dom": "^12.10.3",
    "husky": "^8.0.3",
    "is-ci": "^3.0.1",
    "lint-staged": "^15.0.2",
    "publint": "^0.2.5",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-router-dom": "^6.18.0",
    "sass": "^1.69.5",
    "tsup": "^7.2.0",
    "typescript": "^5.2.2",
    "vite": "^4.5.0",
    "vitest": "^0.34.6"
  }
}
