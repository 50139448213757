export const KnownBrowsers = {
  chrome: 'Google Chrome',
  brave: 'Brave',
  crios: 'Google Chrome',
  edge: 'Microsoft Edge',
  edg: 'Microsoft Edge',
  edgios: 'Microsoft Edge',
  fennec: 'Mozilla Firefox',
  jsdom: 'JsDOM',
  mozilla: 'Mozilla Firefox',
  fxios: 'Mozilla Firefox',
  msie: 'Microsoft Internet Explorer',
  opera: 'Opera',
  opios: 'Opera',
  opr: 'Opera',
  opt: 'Opera',
  rv: 'Microsoft Internet Explorer',
  safari: 'Safari',
  samsungbrowser: 'Samsung Browser',
  electron: 'Electron',
};

export const KnownPlatforms = {
  'android': 'Android',
  'androidTablet': 'Android Tablet',
  'cros': 'Chrome OS',
  'fennec': 'Android Tablet',
  'ipad': 'IPad',
  'ipod': 'IPod',
  'iphone': 'IPhone',
  'jsdom': 'JsDOM',
  'linux': 'Linux',
  'mac': 'Macintosh',
  'tablet': 'Android Tablet',
  'win': 'Windows',
  'windows phone': 'Windows Phone',
  'xbox': 'Microsoft Xbox',
};
